/****************************************
      RESET CSS
****************************************/

/*html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
!* HTML5 display-role reset for older browsers *!
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}


* {
    box-sizing: border-box;
}*/


/****************************************
      MAP
****************************************/
.tk-map {
    position: relative;
    height: 100%;
}
.containerMap {
    width: 100%;
    height: 100%;
    position: relative;
    font-family: arial;
    //background-color: #f7f7f7;
}
.containerMap.loading {
    min-height: 500px;
}
.containerMap svg img:not([src]) {
    visibility: visible;
}

.containerMap--overlay {
    opacity: 0.5;
    pointer-events: none;
}

.modalMap {
    display: none;
    position: absolute;
    z-index: 999999;
    background-color: #fff;
    box-shadow: 0 10px 15px 8px rgba(0,0,0,0.15);
    font-family: "arial", sans-serif;
    font-size: 14px;
    text-align: right;
    max-width: 100%;
    min-width: 400px;
    padding: 15px;
    box-sizing: border-box;
}

.modalInfosContainerMap {
    display: flex;
    border: 2px solid #e6e6e6;
    line-height: 20px;
    justify-content: center;
    align-items: center;
    margin: 15px auto;
    width: max-content;
}

.modal__addToCart__container {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}


.modal__warningRules {
    text-align: center;
}

.modal__addToCart__price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    &:first-child {
        margin-top: 0;
    }
    label {
        width: 100%;
    }
}

.modal__addToCart__price__label__title {
    text-align: left;
    padding-right: 10px;
}

.modal__addToCart__price__label__description {
    text-transform: lowercase;
    text-align: left;
    display: block;
    font-size: 13px;
    font-style: italic;
    max-width: 500px;
}

.modal__addToCart__price__label__title:first-letter {
    text-transform:capitalize;
}

.modal__addToCart__price__label {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
}
.bck-input {
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    width: 20px;
    height: 20px;
    margin: 0 8px;
}
.bck-input.checked {
    background-color: #0a568c;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    width: 20px;
    height: 20px;
    margin: 0 8px;
}
.modal__addToCart__price__input {
    border: none;
    border-radius: 5px;
    margin:0!important;
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: block;
    overflow: hidden;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: white;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: .2s ease-in-out;
    transition-property: background-color,border;
}
.modal__addToCart__price__input:checked {
    background-color: white;
    background-size: contain;
    border-color: transparent;
    mask-image: url(../img/check.svg);
    mask-position: 50% 45%;
    mask-size: 70%;
    mask-repeat: no-repeat;
    -webkit-mask-image: url(../img/check.svg);
    -webkit-mask-position: 50% 45%;
    -webkit-mask-size: 70%;
    -webkit-mask-repeat: no-repeat;
}

.modal__addToCart__price__input:focus {
    outline: none;
}

.modal__addToCart__price__validate {
    background-color: #012C6B;
    padding: 0px 45px;
    border-radius: 30px;
    border: none;
    text-transform: inherit;
    line-height: 50px;
    height: 50px;
    font-size: 16px;
    color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    cursor: pointer;
    width: fit-content;
    -webkit-appearance: unset;
}

.modal__addToCart__price__loading {
    background-color: #012C6B;
    padding: 0 30px 0 20px;
    border-radius: 30px;
    border: none;
    text-transform: inherit;
    line-height: 50px;
    height: 50px;
    font-size: 16px;
    color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    width: fit-content;
    min-width: 160px;
}

.modal__addToCart__text {
    line-height: 50px!important;
    color: #fff!important;
    margin-left: 10px;
}

.modal__addToCart__price__validate:hover {
    //background-color: rgb(3, 25, 58) !important;
}

.modal__addToCart__price__validate:focus {
    outline: none;
}

.modal__addToCart__price__label__amount {
    margin-right: 15px;
}

.modal__InfoArea {
    padding: 15px;
    border-right: 2px solid #e6e6e6;
    text-transform: lowercase;
    justify-content: center;
    align-items: center;
    display: flex;
}

.modal__InfoArea__text:first-letter {
    text-transform:capitalize;
}

.modal__categories {
    padding: 15px;
    border-right: 2px solid #e6e6e6;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.modal__categories__text {
    text-transform: lowercase;
}

.modal__categories__text:first-letter {
    text-transform:capitalize;
}

.modal__price {
    padding: 15px;
    //justify-content: center;
    //align-items: center;
    //display: flex;
    span {
        margin-left: 3px;
    }
}

.modal__holders_container {
    padding-right: 10px;
    margin-top: 20px;
}
.modal__holders_container p {
    text-align: left;
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 600;
}
.modal__holders_container select {
    width: 100%;
    padding: 5px 3px;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
}


.modal__close {
    position: relative;
    width: 20px;
    height: 20px;
    display: inline-block;
}

.modal__close:hover {
    opacity: 1;
}

.modal__close:before, .modal__close:after {
    position: absolute;
    right: 50%;
    content: ' ';
    height: 20px;
    width: 0.5px;
    background-color: black;
}

.modal__close:before {
    transform: rotate(45deg);
}

.modal__close:after {
    transform: rotate(-45deg);
}

.modal__addToCart__error {
    text-align: center;
    color: rgb(212, 1, 1);
    font-weight: bold;
}


.loaderContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: #f7f7f7d9;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bold {
    font-weight: bold;
}

.area--current {
    stroke: grey;
    stroke-width: 2px;
    fill-opacity: 0.4;
}

.area--selected {
    stroke: black;
    stroke-width: 2px;
    fill-opacity: 0.8;
}

.zones.area--available {
    fill: transparent;
}

.zones.area--unavailable {
    fill: black;
}

.seats.area--unavailable {
    fill-opacity: 0;
}

.seats.dsp-area--unavailable {
    stroke: #000;
    stroke-width: 2px;
    fill-opacity: 0;
}

.area--available:hover {
    cursor: pointer;
}

.area--type-strapontin {
    stroke: #b1b1b1;
    stroke-dasharray: 2;
    stroke-width: 4px;
}

.area--unhighlighted {
    fill-opacity: .2;
}

[data-view="2d"] .area--unhighlighted {
    fill-opacity: 0.7;
    fill: #fff!important;
    cursor: auto;
}

.filter--unhighlighted {
    opacity: .3;
}

.backButtonMap {
    position: absolute;
    padding: 10px 20px;
    bottom: 20px;
    left: 20px;
    background-color: white;
    font-size: 16px;
    color: #939598;
    cursor: pointer;
    font-weight: bold;
}

.resetMargin__text {
    margin: 0px;
}

.containerZoomMap {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: row-reverse;
}

.zoomBtnMap {
    cursor: pointer;
    background-color: white;
    font-size: 29px;
    width: 40px;
    color: #939598;
    margin-left: 5px;
    border: 0px;
    padding-bottom: 4px;
    padding-top: 0px;
    font-weight: bold;
}

.zoomOutMap {
    margin-right: 10px;
}

.zoomInMap {
    margin-left: 10px;
}

.containerZoom {
    cursor: pointer;
    background-color: white;
    font-size: 35px;
    font-weight: bold;
    width: 40px;
    height: 40px;
    color: #939598;
    margin-left: 10px;
    position: relative;
}

.hide {
    display: none;
}


.clickedSeat {
    fill: yellow;
    stroke: black;
    stroke-width: 2px;
}


/****************************************
      LOADER
****************************************/


.filter--container .category-slider-item-color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

.filter--container .uk-text-large {
    font-size: 20px;
    line-height: 1;
}

.filter--container .slider-cat-item {
    padding-bottom: 3px;
    cursor: pointer;
    //opacity: 0;
    .category-price {
        &.soldout {
            display: block;
            padding-left: 16px;
            font-weight: 300;
        }
    }
    //&.uk-active {
    //    opacity: 1;
    //}
    &.active .category-price {
        padding-bottom: 3px;
        border-bottom: 2px solid #000;
    }
}


/****************************************
      LOADER
****************************************/

.sk-cube-grid {
    width: 40px;
    height: 40px;
    margin: 100px auto;
}

.sk-cube-grid .sk-cube {
    width: 33%;
    height: 33%;
    background-color: #333;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s; }

@-webkit-keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    } 35% {
          -webkit-transform: scale3D(0, 0, 1);
          transform: scale3D(0, 0, 1);
      }
}

@keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    } 35% {
          -webkit-transform: scale3D(0, 0, 1);
          transform: scale3D(0, 0, 1);
      }
}

.modal__addToCart__spinner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sk-fading-circle {
    width: 25px;
    height: 25px;
    position: relative;
    margin-right: 10px;
}


.sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.sk-fading-circle .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: white;
    border-radius: 100%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
}


@media (max-width: 640px) {


    .modalMap {
        left: 0;
        min-width: auto;
        text-align: center;
    }

    .modal__addToCart__container {
        width: 90%;
    }

    .modalInfosContainerMap {
        width: auto;
        max-width: calc(100% - 30px);
    }

    .filter--container .uk-slidenav {
        margin: 0;
        top: 0;
        transform: translateY(0);
        &.uk-slidenav-next {
            background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9) 30%, #fff 50%);
            margin-right: -1px;
            padding: 10px 20px 10px 20px;
        }
        &.uk-slidenav-previous {
            background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9) 30%, #fff 50%);
            margin-left: -1px;
            padding: 10px 20px 10px 20px;
        }
    }

}

